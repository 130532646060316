<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-card>
          <b-card-title>风险数</b-card-title>
          <div class="d-flex align-items-end">
            <div>
              <div class="d-flex align-items-center">
                <h1 style="
                                        font-size: 2.5rem;
                                        font-weight: 500;
                                    " class="my-0">
                  {{
                    translateUnitToK(
                        riskStatics.day_date_count
                    )
                  }}
                </h1>
                <b-badge v-if="riskStatics.MoM > 0" variant="light-danger" class="ml-50">+{{
                    (riskStatics.MoM * 100).toFixed(2)
                  }}%
                </b-badge>
                <b-badge v-if="riskStatics.MoM < 0" variant="light-success" class="ml-50">{{
                    (riskStatics.MoM * 100).toFixed(2)
                  }}%
                </b-badge>
              </div>
              <p>今日风险数</p>
            </div>
            <div class="ml-4">
              <h2 class="my-0">
                {{
                  translateUnitToK(
                      riskRecordInfo.risk_device_total
                  )
                }}
              </h2>
              <p>今日风险设备</p>
            </div>
          </div>

          <div class="d-flex mt-50">
            <div>
              <h2 class="my-0">
                {{
                  translateUnitToK(
                      riskStatics.month_date_count
                  )
                }}
              </h2>
              <p>本月风险累计</p>
            </div>

            <div class="ml-4">
              <h2 class="my-0">
                {{
                  translateUnitToK(
                      riskStatics.year_date_count
                  )
                }}
              </h2>
              <p>年度风险累计</p>
            </div>
          </div>
        </b-card>

        <b-card>
          <b-row>
            <b-col cols="8">
              <b-tabs>
                <b-tab @click="handleClickChangePieData(0)" title="程度" active>
                </b-tab>
                <b-tab @click="handleClickChangePieData(4)" title="系统"></b-tab>
                <b-tab @click="handleClickChangePieData(5)" title="类型"></b-tab>
                <b-tab @click="handleClickChangePieData(3)" title="范畴">
                </b-tab>

                <div id="container2" style="height: 13.5rem"></div>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-1">
          <b-tabs>
            <b-tab @click="
                            handleChangeRiskStatics(
                                riskStatics.year_data
                            )
                            " title="近12月" active>
            </b-tab>
            <b-tab @click="
                            handleChangeRiskStatics(
                                riskStatics.day_14_data
                            )
                            " title="近14日"></b-tab>
            <b-tab @click="
                            handleChangeRiskStatics(
                                riskStatics.hour_24_data
                            )
                            " title="近24时"></b-tab>

            <div id="container1" style="height: 11.5rem;"></div>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="4" class="d-flex flex-column justify-content-between">
        <b-button-group class="mb-1" style="z-index: 3">
          <b-button variant="flat-primary">风险列表</b-button>
          <b-button variant="flat-primary">风险日历</b-button>
          <b-button variant="flat-primary">推理过程</b-button>
          <b-button variant="flat-primary">追踪溯源</b-button>
          <b-button variant="flat-primary">规则图谱</b-button>
        </b-button-group>

        <p class="text-center">Powered by SciPlat engine. All rights reserved.</p>
        <!-- <b-row style="z-index: 3">
            <b-col cols="4">
                <b-card bg-variant="light-danger">
                    <span class="bullet bullet-danger bullet-sm mr-50"></span>
                    严重
                    <span class="ml-2" style="font-size: 1.5rem">
                        {{
                            translateUnitToK(
                                riskRecordInfo.serious
                            )
                        }}
                    </span>
                </b-card>
            </b-col>

            <b-col cols="4">
                <b-card bg-variant="light-warning">
                    <span class="bullet bullet-warning bullet-sm mr-50"></span>
                    一般
                    <span class="ml-2" style="font-size: 1.5rem">
                        {{
                            translateUnitToK(
                                riskRecordInfo.commonly
                            )
                        }}
                    </span></b-card>
            </b-col>

            <b-col cols="4">
                <b-card bg-variant="light-info">
                    <span class="bullet bullet-info bullet-sm mr-50"></span>
                    提醒
                    <span class="ml-2" style="font-size: 1.5rem">
                        {{
                            translateUnitToK(
                                riskRecordInfo.remind
                            )
                        }}
                    </span>
                </b-card>
            </b-col>
        </b-row> -->

      </b-col>

      <b-col cols="4">
        <b-card>
          <b-card-title>风险率</b-card-title>
          <b-row>
            <b-col cols="5" offset="2">
              <div id="container6" style="height: 12rem"></div>
            </b-col>
            <b-col cols="5">
              <h1 style="font-size: 2.5rem" class="mt-2 mb-0 text-right">
                {{ translateUnitToK(topInfos.data_total) }}
              </h1>
              <p class="text-right">诊断数据总量</p>

              <div class="d-flex justify-content-end mt-2">
                <div>
                  <h2 class="my-0 text-right">
                    {{ translateUnitToK(topInfos.point_total) }}
                  </h2>
                  <p class="text-right">诊断总点位数</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-row>
            <b-col cols="8" offset="4">
              <div class="d-flex align-items-center">
                <b-avatar rounded size="28" variant="light-primary">
                  <b-icon-brush-fill scale="1.25"/>
                </b-avatar>
                <span class="ml-50">即时诊断</span>
              </div>
              <div class="d-flex align-items-end justify-content-between mt-50">
                <span>风险率</span>
                <h2 class="mb-0">
                  {{
                    (
                        (topInfos.immed_fault_total /
                            topInfos.immed_total) *
                        100
                    ).toFixed(0)
                  }}%
                </h2>
              </div>

              <b-progress :value="(
                                (topInfos.immed_fault_total /
                                    topInfos.immed_total) *
                                100
                            ).toFixed(0)
                                " max="100" height=".5rem" variant="primary" class="my-50"></b-progress>

              <div class="d-flex align-items-center justify-content-between">
                <span>风险数</span>
                <span>{{ topInfos.immed_total }}</span>
              </div>

              <div class="d-flex align-items-center justify-content-between">
                <span>诊断数</span>
                <span>{{ topInfos.immed_fault_total }}</span>
              </div>

              <div class="d-flex align-items-center justify-content-between">
                <span>规则数</span>
                <span>{{ topInfos.immed_rule_total }}</span>
              </div>

              <div class="d-flex align-items-center" style="margin-top: 1.5rem">
                <b-avatar rounded size="28" variant="light-info">
                  <b-icon-droplet-half scale="1.25"/>
                </b-avatar>
                <span class="ml-50">延时诊断</span>
              </div>
              <div class="d-flex align-items-end justify-content-between mt-50">
                <span>风险率</span>
                <h2 class="mb-0">
                  {{
                    (
                        (topInfos.delay_fault_total /
                            topInfos.delay_total) *
                        100
                    ).toFixed(0)
                  }}%
                </h2>
              </div>
              <b-progress :value="(
                                (topInfos.delay_fault_total /
                                    topInfos.delay_total) *
                                100
                            ).toFixed(2)
                                " max="100" height=".5rem" variant="info" class="my-50"></b-progress>

              <div class="d-flex align-items-center justify-content-between">
                <span>风险数</span>
                <span>{{ topInfos.delay_total }}</span>
              </div>

              <div class="d-flex align-items-center justify-content-between">
                <span>诊断数</span>
                <span>{{ topInfos.delay_fault_total }}</span>
              </div>

              <div class="d-flex align-items-center justify-content-between">
                <span>规则数</span>
                <span>{{ topInfos.delay_rule_total }}</span>
              </div>

            </b-col>
          </b-row>
        </b-card>

        <b-card class="mb-1">
          <b-row>
            <b-col cols="6">
              <div id="container7" style="height: 8.5rem"></div>
            </b-col>
            <b-col cols="6">
              <p class="mt-1">
                <b-icon-bell-fill class="mr-1"/>
                待处理
                <b-badge variant="light-primary" class="float-right">{{ DisposalRate.unprocessed }}</b-badge>
              </p>
              <p>
                <b-icon-check2-square class="mr-1"/>
                已推送
                <b-badge variant="light-primary" class="float-right">{{ DisposalRate.pushed }}</b-badge>
              </p>
              <p>
                <b-icon-trash-fill class="mr-1"/>
                已忽略
                <b-badge variant="light-primary" class="float-right">{{ DisposalRate.ignored }}</b-badge>
              </p>
            </b-col>
          </b-row>
          <!-- <b-row>
              <b-col cols="4">
                  <div class="d-flex align-items-center border rounded p-1">
                      <b-avatar rounded size="36" variant="light-primary">
                          <b-icon-bell-fill />
                      </b-avatar>
                      <div class="ml-1">
                          <p class="my-0">待处理</p>
                          <h3 class="my-0">0</h3>
                      </div>
                  </div>
              </b-col>

              <b-col cols="4">
                  <div class="d-flex align-items-center border rounded p-1">
                      <b-avatar rounded size="32" variant="light-primary">
                          <b-icon-check2-square />
                      </b-avatar>
                      <div class="ml-75">
                          <p class="my-0">已推送</p>
                          <h3 class="my-0">0</h3>
                      </div>
                  </div>
              </b-col>

              <b-col cols="4">
                  <div class="d-flex align-items-center border rounded p-1">
                      <b-avatar rounded size="32" variant="light-primary">
                          <b-icon-trash-fill />
                      </b-avatar>
                      <div class="ml-75">
                          <p class="my-0">已忽略</p>
                          <h3 class="my-0">0</h3>
                      </div>
                  </div>
              </b-col>
          </b-row> -->

          <!-- <b-row>
              <b-col cols="6">
                  <b-alert show variant="primary">
                      <div class="alert-body">
                          <h1 style="font-size: 2.5rem" class="mb-0 mt-25">
                              {{
                                  translateUnitToK(
                                      riskRecordInfo.total
                                  )
                              }}
                          </h1>
                          <p>风险总数</p>
                      </div>
                  </b-alert>
              </b-col>
              <b-col cols="6">
                  <b-alert show variant="primary">
                      <div class="alert-body">

                      </div>
                  </b-alert>
              </b-col>
          </b-row> -->


        </b-card>
      </b-col>

      <b-card
          style="border-radius: 370px; width: 50%; position: absolute; top: calc(50% + 50px); left: 50%; transform: translate(-50%, -50%); z-index: 2; box-shadow: none"
          :style="`border: 60px solid ${borderColor};`">
        <div class="d-flex justify-content-center">
          <b-card style="width: 50%; background-color: transparent;" class="my-1">
            <div class="d-flex align-items-center">
              <div style="width: calc(100% - 110px)" class="pr-2">
                <b-card-title>健康度</b-card-title>
                {{ healthInfo.description }}
              </div>
              <div style="width: 110px">
                <h1 style="font-size: 3.5rem; white-space: nowrap;" class="mb-0">
                  {{ healthInfo.score }}%
                </h1>
                <b-icon-star-fill v-for="(
                                            _, index
                                        ) of healthInfo.start_num" :key="index + 'j'" variant="success"
                                  :class="index === 0 ? '' : 'ml-50'"></b-icon-star-fill>
                <b-icon-star-half v-for="(
                                            _, index
                                        ) of healthInfo.start_half_num" :key="index + 'j'" variant="success"
                                  class="ml-50"></b-icon-star-half>
                <b-icon-star v-for="(
                                            _, index
                                        ) of healthInfo.start_empty_num" :key="index + 'j'" variant="success"
                             class="ml-50"></b-icon-star>
              </div>
            </div>
          </b-card>
        </div>

        <div id="container3" style="height: 18rem"></div>

        <div class="d-flex justify-content-center mt-2">
          <b-card style="width: 66.67%; background-color: transparent;">
            <b-row>
              <b-col cols="4">
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="28" variant="light-primary">
                    <b-icon-shield-fill-exclamation scale="1.25"/>
                  </b-avatar>
                  <span class="ml-50">安全性</span>
                </div>
                <h2 class="mt-50">79%</h2>
                <b-progress value="79" max="907" height=".5rem" variant="primary"></b-progress>
              </b-col>

              <b-col cols="4">
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="28" variant="light-info">
                    <b-icon-thermometer-half scale="1.25"/>
                  </b-avatar>
                  <span class="ml-50">舒适性</span>
                </div>
                <h2 class="mt-50">72%</h2>
                <b-progress value="272" max="746" height=".5rem" variant="info"></b-progress>
              </b-col>

              <b-col cols="4">
                <div class="d-flex align-items-center">
                  <b-avatar rounded size="28" variant="light-success">
                    <b-icon-lightning-fill scale="1.25"/>
                  </b-avatar>
                  <span class="ml-50">节能性</span>
                </div>
                <h2 class="mt-50">70%</h2>
                <b-progress value="272" max="746" height=".5rem" variant="success"></b-progress>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-card>
    </b-row>
  </div>
</template>


<script>
import {Column, Pie, Gauge, RadialBar, Bar, Liquid} from "@antv/g2plot";
import {
  onMounted,
  ref,
  reactive,
  toRefs,
  computed,
} from "@vue/composition-api";
import {scroll_active, scroll_stop} from "@/libs/utils/autoScroll";
import store from "@/store";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import FunctionEntry from "@/views/front/cabr/dashboard/components/FunctionEntry.vue";
import {
  getDiagnosisDisposalRate,
  getDiagnosisRiskDevices,
  getDiagnosisRiskRecordInfo,
  getDiagnosisRiskStatics,
} from "@/api/diagnosis";
import router from "@/router/index";
import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {showToast} from "@/libs/utils/showToast";
import DataVisual from "@/views/front/cabr/dashboard/components/DataVisual.vue";
import HeatMap from "@/views/front/cabr/diagnosis/risk/components/heatmap.vue";

export default {
  name: "test-g2-plot",
  components: {
    HeatMap,
    DataVisual,
    FunctionEntry,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
  },
  setup() {
    // 首页顶部信息
    const event = reactive({
      DisposalRate: {
        ignored: 0,
        pushed: 0,
        unprocessed: 0
      },
      projectOperation: [
        {
          time: "2023-01-01",
          title: "风险名称",
          subtitle: "风险描述",
          degree: "严重",
        },
        {
          time: "2023-02-01",
          title: "风险名称",
          subtitle: "风险描述",
          degree: "一般",
        },
        {
          time: "2023-01-01",
          title: "风险名称",
          subtitle: "风险描述",
          degree: "提醒",
        },
        {
          time: "2023-02-01",
          title: "风险名称",
          subtitle: "风险描述",
          degree: "提醒",
        },
        {
          time: "2023-01-01",
          title: "风险名称",
          subtitle: "风险描述",
          degree: "严重",
        },
      ],
      project_id: null,
      topInfos: {
        health: {
          date: "",
          degree: "",
          score: 0,
        },
        data_total: 0,
        point_total: 0,
        immed_total: 0,
        immed_fault_total: 0,
        immed_rule_total: 0,
        delay_total: 0,
        delay_fault_total: 0,
        delay_rule_total: 0,
      },
      healthInfo: {
        description: "智能分析中，请稍后...",
        score: 100,
        start_num: 5,
        start_half_num: 0,
        start_empty_num: 0,
      },
      riskRecordInfo: {
        commonly: 0,
        remind: 0,
        serious: 0,
        total: 0,
        risk_device_total: 0,
        device_total: 0,
      },
      riskStatics: {
        year_data: [],
        day_14_data: [],
        hour_24_data: [],
        day_date_count: 0,
        month_date_count: 0,
        year_date_count: 0,
        MoM: 0,
      },
      agentPie: [],
      riskDevices: [],
    });
    const gauge = ref(null);
    const liquidPlot = ref(null);
    const columnPlot = ref(null);
    const piePlot = ref(null);
    // const bar = ref(null);
    const translateUnitToK = (num) => {
      if (num < 1000) return num;
      return (
          new Intl.NumberFormat("ja-JP").format((num / 1000).toFixed(1)) +
          "k"
      );
    };
    const borderColor = computed(() => {
      const skin = store.state.appConfig.layout.skin;
      if (skin === 'dark') {
        return '#161d31'
      }
      return '#f8f8f8'
    });


    getDiagnosisRiskRecordInfo().then((res) => {
      event.riskRecordInfo = res.data;
      if (liquidPlot.value) {
        liquidPlot.value.update({
          statistic: {
            title: "风险率",
            content: {
              formatter: () =>
                  Math.ceil(
                      (event.riskRecordInfo.risk_device_total /
                          event.riskRecordInfo.device_total) *
                      10000
                  ) + "%",
            },
          },
          percent:
              (event.riskRecordInfo.risk_device_total /
                  event.riskRecordInfo.device_total) *
              100,
        });
      }
    });

    getDiagnosisRiskDevices().then((res) => {
      event.riskDevices = res.data;
    });

    const handleToFaultDeviceList = (eqname) => {
      router.push({
        name: "FunMenu_AgentList",
        params: {
          eqname,
        },
      });
    };
    const handleToFaultDeviceInfo = (id) => {
      router.push({
        name: "AgentDetail",
        params: {
          id,
        },
      });
    };

    const handleToFaultList = () => {
      router.push({
        name: "index-agent-list",
      });
    };

    const numberFormat = (value) => {
      if (!value) return [0, ""];
      let unit = "";
      let k = 10000,
          sizes = ["", "万", "亿", "万亿"],
          i;
      if (value < k) {
        value = value;
      } else {
        i = Math.floor(Math.log(value) / Math.log(k));
        value = (value / Math.pow(k, i)).toFixed(2);
        unit = sizes[i];
      }
      return [value, unit];
    };

    const handleChangeRiskStatics = (data) => {
      columnPlot.value.changeData(data);
    };
    store.dispatch("diagnosis/fetchNotice", 7);
    const handleClickChangePieData = (index) => {
      if (piePlot.value) {
        piePlot.value.destroy();
        // return;
        if (index == 4) {
          // piePlot.value.options.colorField = 'system'
          piePlot.value = new Pie("container2", {
            appendPadding: 10,
            data: event.agentPie[index],
            angleField: "num",
            colorField: "system",
            radius: 1,
            innerRadius: 0.6,
            label: {
              type: "inner",
              offset: "-50%",
              content: "{value}",
              style: {
                textAlign: "center",
                fontSize: 14,
              },
            },
            pieStyle: {
              lineWidth: 0,
            },
            interactions: [
              {type: "element-selected"},
              {type: "element-active"},
            ],
            statistic: {
              title: false,
              content: {
                style: {
                  color: '#82868b',
                },
              }
            },
          });
        } else if (index == 5) {
          // piePlot.value.options.colorField = 'type'
          piePlot.value = new Pie("container2", {
            appendPadding: 10,
            data: event.agentPie[index],
            angleField: "num",
            colorField: "type",
            radius: 1,
            innerRadius: 0.6,
            label: {
              type: "inner",
              offset: "-50%",
              content: "{value}",
              style: {
                textAlign: "center",
                fontSize: 14,
              },
            },
            pieStyle: {
              lineWidth: 0,
            },
            interactions: [
              {type: "element-selected"},
              {type: "element-active"},
            ],
            statistic: {
              title: false,
              content: {
                style: {
                  color: '#82868b',
                },
              }
            },
          });
          // piePlot.value.updateConfig({
          //   angleField: "num",
          //   colorField: "type",
          // })
        } else if (index == 3) {
          // piePlot.value.options.colorField = 'type'
          piePlot.value = new Pie("container2", {
            appendPadding: 10,
            data: event.agentPie[index],
            angleField: "num",
            colorField: "primary_class",
            radius: 1,
            innerRadius: 0.6,
            label: {
              type: "inner",
              offset: "-50%",
              content: "{value}",
              style: {
                textAlign: "center",
                fontSize: 14,
              },
            },
            pieStyle: {
              lineWidth: 0,
            },
            interactions: [
              {type: "element-selected"},
              {type: "element-active"},
            ],
            statistic: {
              title: false,
              content: {
                style: {
                  color: '#82868b',
                },
              }
            },
          });
          // piePlot.value.updateConfig({
          //   angleField: "num",
          //   colorField: "type",
          // })
        } else {
          // piePlot.value.options.colorField = 'degree'
          piePlot.value = new Pie("container2", {
            appendPadding: 10,
            data: event.agentPie[index],
            angleField: "num",
            colorField: "degree",
            radius: 1,
            innerRadius: 0.6,
            label: {
              type: "inner",
              offset: "-50%",
              content: "{value}",
              style: {
                textAlign: "center",
                fontSize: 14,
              },
            },
            pieStyle: {
              lineWidth: 0,
            },
            interactions: [
              {type: "element-selected"},
              {type: "element-active"},
            ],
            statistic: {
              title: false,
              content: {
                style: {
                  color: '#82868b',
                },
              }
            },
          });
          // piePlot.value.updateConfig({
          //   angleField: "num",
          //   colorField: "degree",
          // })
        }
        piePlot.value.render();
      }
    };

    const getAgentPie = () => {
      const project_id = event.project_id;
      store
          .dispatch("projects/fetchAgentPie", project_id)
          .then((response) => {
            event.agentPie = response.data;
            if (piePlot.value) {
              piePlot.value.changeData(event.agentPie[0]);
            }
            // if (bar.value) {
            //     bar.value.changeData(
            //         event.agentPie[3].sort((x, y) => y.num - x.num)
            //     );
            // }
          })
          .catch((error) => {
            showToast(error);
          });
    };
    getAgentPie();

    getDiagnosisRiskStatics().then((res) => {
      event.riskStatics = res.data;
      if (columnPlot.value) {
        columnPlot.value.changeData(res.data.year_data);
      }
    });

    const getIndexTopInfos = () => {
      const project_id = event.project_id;
      store
          .dispatch("projects/getIndexTopInfos")
          .then((response) => {
            event.topInfos = response.data;
            event.healthInfo.score = Math.ceil(
                event.topInfos.health.score
            );
            event.healthInfo.start_num = Math.floor(
                event.topInfos.health.score / 20
            );
            event.healthInfo.start_half_num = Math.ceil(
                event.topInfos.health.score % 1
            );
            event.healthInfo.start_empty_num =
                5 -
                event.healthInfo.start_num -
                event.healthInfo.start_half_num;
            if (gauge.value) {
              // gauge.value.percent = event.healthInfo.score / 100
              gauge.value.update({
                statistic: {
                  title: "",
                  content: {
                    style: {
                      fontSize: "24px",
                      lineHeight: "44px",
                      color: "#4B535E",
                    },
                    formatter: () =>
                        Math.ceil(event.topInfos.health.score) +
                        "%",
                  },
                },
                percent:
                    Math.ceil(event.topInfos.health.score) / 100,
              });
            }
            switch (event.topInfos.health.degree) {
              case "较差":
                event.healthInfo.description =
                    "今日健康度较差，风险极高，需立即处理。";
                break;
              case "及格":
                event.healthInfo.description =
                    "今日健康度欠佳，风险频繁，需及时处理。";
                break;
              case "优秀":
                event.healthInfo.description =
                    "今日健康度优秀，设备运行良好，请继续保持。";
                break;
              case "良好":
                event.healthInfo.description =
                    "今日健康度良好，请持续关注设备运行状态。";
                break;
              default:
                event.healthInfo.description = "目前为止数据";
            }
          })
          .catch((error) => {
            showToast(error);
          });
    };

    getIndexTopInfos();

    const getCurrentDate = () => {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    };

    onMounted(() => {
      scroll_active("#project-detail-operation-scroll-notice1");
    });

    return {
      ...toRefs(event),
      scroll_active,
      scroll_stop,
      numberFormat,
      handleChangeRiskStatics,
      handleClickChangePieData,
      translateUnitToK,
      getCurrentDate,
      handleToFaultDeviceList,
      handleToFaultDeviceInfo,
      handleToFaultList,
      piePlot,
      columnPlot,
      liquidPlot,
      store,
      gauge,
      borderColor
    };
  },

  mounted() {
    const data1 = [];
    const data2 = [];

    getDiagnosisDisposalRate().then(res => {
      this.DisposalRate = res.data
      const percent = (this.DisposalRate.ignored + this.DisposalRate.pushed) / (this.DisposalRate.ignored + this.DisposalRate.pushed + this.DisposalRate.unprocessed)
      this.gauge2 = new Gauge('container7', {
        percent,
        range: {
          color: ['l(0) 0:#1890ff1f 1:#1890ff', '#1890ff1f'],
        },
        gaugeStyle: {
          lineCap: 'round',
        },
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        statistic: {
          title: {
            offsetY: -28,
            style: {
              fontSize: '2rem',
              color: '#82868b',
            },
            formatter: () => `${(percent * 100).toFixed(1)}%`,
          },
          content: {
            offsetY: -42,
            style: {
              fontSize: '1rem',
              lineHeight: '1rem',
              color: '#82868b',
            },
            formatter: () => '处置率',
          },
        },
      });
      this.gauge2.render()
    })

    const data4 = [
      {name: "安全", star: 297},
      {name: "舒适", star: 506},
      {name: "节能", star: 805},
    ];

    const data5 = [
      {year: "数据质量", value: 764},
      {year: "硬件联动", value: 118},
      {year: "运行效果", value: 14},
      {year: "控制逻辑", value: 11},
    ];

    this.columnPlot = new Column("container1", {
      data: data1,
      xField: "date",
      yField: "value",
      label: {
        position: "middle",
        style: {
          fill: "#FFFFFF",
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
        line: null,
        grid: null,
        tickLine: null,
      },
      yAxis: {
        line: null,
        grid: null,
        label: null,
      },
      columnStyle: {
        radius: [5, 5, 5, 5],
      },
      meta: {
        type: {
          alias: "类别",
        },
        sales: {
          alias: "销售额",
        },
      },
    });

    this.piePlot = new Pie("container2", {
      appendPadding: 10,
      data: data2,
      angleField: "num",
      colorField: "degree",
      radius: 1,
      innerRadius: 0.6,
      label: {
        type: "inner",
        offset: "-50%",
        content: "{value}",
        style: {
          textAlign: "center",
          fontSize: 14,
        },
      },
      pieStyle: {
        lineWidth: 0,
      },
      interactions: [
        {type: "element-selected"},
        {type: "element-active"},
      ],
      statistic: {
        title: false,
        content: {
          style: {
            color: '#82868b',
          },
        }
      },
    });

    this.gauge = new Gauge("container3", {
      percent: 100,
      type: "meter",
      innerRadius: 0.75,
      range: {
        ticks: [0, 1 / 3, 2 / 3, 1],
        color: ["#ea5455", "#ff9f43", "#28c76f"],
      },
      indicator: {
        pointer: {
          style: {
            stroke: "#D0D0D0",
          },
        },
        pin: {
          style: {
            stroke: "#D0D0D0",
          },
        },
      },
      axis: {
        label: {
          formatter(v) {
            return Number(v) * 100;
          },
        },
        subTickLine: {
          count: 3,
        },
      },
    });

    /* this.radialBar = new RadialBar("container4", {
        data: data4,
        xField: "name",
        yField: "star",
        maxAngle: 270, //最大旋转角度,
        radius: 0.8,
        innerRadius: 0.2,
        barStyle: {
            lineCap: "round",
        },
    }); */

    // this.bar = new Bar("container5", {
    //     data: [],
    //     xField: "num",
    //     yField: "primary_class",
    //     xAxis: {
    //         grid: null,
    //         line: null,
    //     },
    //     yAxis: {
    //         grid: null,
    //         line: null,
    //         tickLine: null,
    //     },
    //     label: {
    //         position: "middle",
    //         style: {
    //             fill: "#FFFFFF",
    //             opacity: 0.6,
    //         },
    //     },
    //     seriesField: "primary_class",
    //     legend: false,
    //     barStyle: {
    //         radius: [10, 10, 10, 10],
    //     },
    // });

    this.liquidPlot = new Liquid("container6", {
      percent: 0,
      outline: {
        border: 4,
        distance: 0,
      },
      wave: {
        length: 128,
      },
      statistic: {
        title: {
          content: "风险率",
        },
      },
    });


    this.columnPlot.render();
    this.piePlot.render();
    this.gauge.render();
    /* this.radialBar.render(); */
    // this.bar.render();
    this.liquidPlot.render();
    // this.gauge2.render();
  },
};


</script>

<style lang="scss" scoped>
/* Lucas */
.row0 {
  height: calc(100vh - 137px);
}

.row1 {
  height: 420px;
}

.row2 {
  height: 368px;
  /* height: calc(100vh - 590px); */
}

.row3 {
  height: 480px;
}

.offsetMargin {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
</style>
