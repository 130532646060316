<template>
    <div id="container" style="width: 100%; height: 100%"></div>

    <!-- <Transition name="fade">
      <div v-show="dialogInfo.show" class="position-absolute w-25 position-top-0 position-right-0 mouse-in-show">
        <b-card>
          <b-card-title>节点信息</b-card-title>
          <ul>
            <li>节点名称:{{ dialogInfo.title }}</li>
            <li>全部连接:{{ dialogInfo.outEdges + dialogInfo.inEdges }}</li>
            <li>入度连接:{{ dialogInfo.inEdges }}</li>
            <li>出度连接:{{ dialogInfo.outEdges }}</li>
          </ul>
        </b-card>
      </div>
    </Transition> -->
    <!-- </div> -->
</template>
<script>
import { nextTick, onMounted, reactive, ref } from "@vue/composition-api";
import G6 from "@antv/g6";
import { data_relationship } from "@/api/diagnosis";

export default {
    name: "DataVisual",
    props: {
        height: {
            type: Number,
            //   default: () => 500,
        },
    },
    setup(props) {
        const data1 = {};
        const container = ref(null);
        const graph = ref(null);
        const dialogInfo = reactive({
            show: false,
            title: "",
            inEdges: 0,
            outEdges: 0,
        });

        const fittingString = (str, maxWidth, fontSize) => {
            const ellipsis = "...";
            const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
            let currentWidth = 0;
            let res = str;
            const pattern = new RegExp("[\u4E00-\u9FA5]+"); // distinguish the Chinese charactors and letters
            str.split("").forEach((letter, i) => {
                if (currentWidth > maxWidth - ellipsisLength) return;
                if (pattern.test(letter)) {
                    // Chinese charactors
                    currentWidth += fontSize;
                } else {
                    // get the width of single letter according to the fontSize
                    currentWidth += G6.Util.getLetterWidth(letter, fontSize);
                }
                if (currentWidth > maxWidth - ellipsisLength) {
                    res = `${str.substr(0, i)}${ellipsis}`;
                }
            });
            return res;
        };

        const initGraph = (data) => {
            const globalFontSize = 8;

            const clusterMap = new Map();
            let clusterId = 0;
            const colors = [
                "#BDD2FD",
                "#BDEFDB",
                "#C2C8D5",
                "#FBE5A2",
                "#F6C3B7",
                "#B6E3F5",
                "#D3C6EA",
                "#FFD8B8",
                "#AAD8D8",
                "#FFD6E7",
            ];
            const strokes = [
                "#5B8FF9",
                "#5AD8A6",
                "#5D7092",
                "#F6BD16",
                "#E8684A",
                "#6DC8EC",
                "#9270CA",
                "#FF9D4D",
                "#269A99",
                "#FF99C3",
            ];
            G6.registerNode(
                "circle-diy",
                {
                    afterDraw(cfg, group) {
                        const size = cfg.size;
                        const width = size[0] - 12;
                        const height = size[1] - 12;
                        let img;
                        if (cfg.data.label.includes("冷机")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/lenji.svg";
                        } else if (cfg.data.label.includes("冷却塔")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/lenqueta.svg";
                        } else if (cfg.data.label.includes("传感器")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/chuanganqi.svg";
                        } else if (cfg.data.label.includes("冷冻水")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/lendongshui.svg";
                        } else if (cfg.data.label.includes("定压")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/dingya.svg";
                        } else if (cfg.data.label.includes("冷却水")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/lenqueshui.svg";
                        } else if (cfg.data.label.includes("冷水机组")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/lenshuijizu.svg";
                        } else if (cfg.data.label.includes("水泵")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/shuibeng.svg";
                        } else if (cfg.data.label.includes("电动风阀")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/diandongfengfa.svg";
                        } else if (cfg.data.label.includes("空调机组")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/kongtiaojizu.svg";
                        } else if (cfg.data.label.includes("堵塞")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/duse.svg";
                        } else if (cfg.data.label.includes("xxxxx")) {
                            img =
                                "https://cabr-1255710621.cos.ap-guangzhou.myqcloud.com/asset/images/xxxxx.svg";
                        } else {
                            console.log(cfg.data.label);
                        }

                        const image = group.addShape("image", {
                            attrs: {
                                x: -8,
                                y: -8,
                                width: 16,
                                height: 16,
                                img,
                            },
                        });
                        // const back1 = group.addShape('circle', {
                        //   zIndex: -3,
                        //   attrs: {
                        //     x: 0,
                        //     y: 0,
                        //     r,
                        //     fill: cfg.color,
                        //     opacity: 0.6,
                        //   },
                        //   // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
                        //   name: 'back1-shape',
                        // });
                    },
                },
                "circle"
            );

            data.nodes.forEach(function (node) {
                // cluster
                node.label = "";

                // node.label = fittingString(node.label, 30, globalFontSize);
                if (
                    node.cluster &&
                    clusterMap.get(node.cluster) === undefined
                ) {
                    clusterMap.set(node.cluster, clusterId);
                    clusterId++;
                }
                const cid = clusterMap.get(node.cluster);
                if (!node.style) {
                    node.style = {};
                }
                node.style.fill = colors[cid % colors.length];
                node.style.stroke = strokes[cid % strokes.length];
                node.data.size = Math.random() * 30 + 5;
            });

            graph.value.changeData(data);
            graph.value.render();
        };

        onMounted(() => {
            G6.registerEdge(
                "smooth",
                {
                    afterDraw(cfg, group) {
                        // get the first shape in the group, it is the edge's path here=
                        const shape = group.get("children")[0];
                        // the start position of the edge's path
                        const startPoint = shape.getPoint(0);

                        // add red circle shape
                        const circle = group.addShape("circle", {
                            attrs: {
                                x: startPoint.x,
                                y: startPoint.y,
                                fill: "#1890ff",
                                r: 2,
                            },
                            name: "circle-shape",
                        });

                        // animation for the red circle
                        circle.animate(
                            (ratio) => {
                                // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
                                // get the position on the edge according to the ratio
                                const tmpPoint = shape.getPoint(ratio);
                                // returns the modified configurations here, x and y here
                                return {
                                    x: tmpPoint.x,
                                    y: tmpPoint.y,
                                };
                            },
                            {
                                repeat: true, // Whether executes the animation repeatly
                                duration: 3000, // the duration for executing once
                            }
                        );
                    },
                    draw(cfg, group) {
                        const { startPoint, endPoint } = cfg;
                        const hgap = Math.abs(endPoint.x - startPoint.x);

                        const path = [
                            ["M", startPoint.x, startPoint.y],
                            [
                                "C",
                                startPoint.x + hgap / 4,
                                startPoint.y,
                                endPoint.x - hgap / 2,
                                endPoint.y,
                                endPoint.x,
                                endPoint.y,
                            ],
                        ];

                        const shape = group.addShape("path", {
                            attrs: {
                                stroke: "#AAB7C4",
                                path,
                            },
                            name: "smooth-path-shape",
                        });
                        return shape;
                    },
                },
                "arc"
            );

            nextTick(() => {
                container.value = document.getElementById("container");
                if (container) {
                    const width = container.value.scrollWidth;
                    const height = props.height || 800;
                    const graphInstance = new G6.Graph({
                        container: "container",
                        width,
                        height,
                        // modes: {
                        //   default: ['drag-canvas', 'drag-node'],
                        // },
                        minZoom: 0.02,
                        maxZoom: 5,
                        layout: {
                            type: "force",
                            // animate: false, // 设置为 false 可关闭布局动画
                            maxSpeed: 80,
                            linkDistance: 80,
                            clustering: true,
                            nodeClusterBy: "cluster",
                            nodeStrength: -150,
                            clusterNodeStrength: -310,
                        },
                        animate: true,
                        defaultNode: {
                            size: 30,
                            type: "circle-diy",
                            style: {
                                fill: "#DEE9FF",
                                stroke: "#5B8FF9",
                            },
                            labelCfg: {
                                style: {
                                    fontSize: 8,
                                    colors: ["#fff"],
                                },
                            },
                        },
                        defaultEdge: {
                            type: "smooth",
                            style: {
                                // endArrow: {
                                //   path: 'M 0,0 L 8,4 L 8,-4 Z',
                                //   fill: '#e2e2e2',
                                // },
                            },
                        },
                        modes: {
                            default: [
                                {
                                    type: "drag-canvas",
                                    enableOptimize: true,
                                },
                                {
                                    type: "zoom-canvas",
                                    enableOptimize: true,
                                    optimizeZoom: 0.01,
                                },
                                "drag-node",
                                "shortcuts-call",
                            ],
                            lassoSelect: [
                                {
                                    type: "zoom-canvas",
                                    enableOptimize: true,
                                    optimizeZoom: 0.01,
                                },
                                {
                                    type: "lasso-select",
                                    selectedState: "focus",
                                    trigger: "drag",
                                },
                            ],
                            fisheyeMode: [],
                        },
                    });
                    graph.value = graphInstance;
                    graph.value.on("node:click", (e) => {
                        const { item } = e;
                        const inEdges = item.getInEdges();
                        const outEdges = item.getOutEdges();
                        item.toFront();
                        dialogInfo.title = item.getModel().data.label;
                        dialogInfo.inEdges = inEdges.length;
                        dialogInfo.outEdges = outEdges.length;
                        dialogInfo.show = true;
                    });
                    graph.value.on("canvas:click", (e) => {
                        dialogInfo.show = false;
                        dialogInfo.title = "";
                        dialogInfo.inEdges = 0;
                        dialogInfo.outEdges = 0;
                    });
                }
            });
            data_relationship().then((res) => {
                data1.nodes = res.data.nodes;
                data1.edges = res.data.edges;
                initGraph(data1);
            });
        });
        return {
            dialogInfo,
        };
    },
};
</script>
<style lang="scss" scoped>
.mouse-in-show:hover {
    opacity: 1;
    transition: 0.1s all ease-in;
}

.mouse-in-show {
    opacity: 0.5;
}
</style>
